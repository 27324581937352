import React from "react";
import Layout from "../components/layout";

const Contact = () => {


    return <Layout>
        <div className="C__container">
            <h1>Napisz do mnie:</h1>
            <h2>barygawojciech@gmail.com
            </h2>
            <h2>Tel: 507025600</h2>
        </div>
    </Layout>
}

export default Contact;